import "../css/index.scss";
import $ from "jquery/dist/jquery";

import "core-js/es/array";

import "bootstrap/dist/js/bootstrap.bundle";

import "@nxcms/nxcms-js/src/js/sentry";

import "@nxcms/nxcms-js/src/js/static";
import "@nxcms/nxcms-js/src/js/blog/index";

import "@nxcms/nxcms-js/src/js/eshop/index";
import "@nxcms/nxcms-js/src/js/eshop/60_price_history_optional";

import "@nxcms/nxcms-js/src/js/ga4-eec/index";
import "@nxcms/nxcms-js/src/js/gallery/index";

import "@nxcms/nxcms-js/src/js/delivery-zasilkovna/index";

import "@nxcms/nxcms-js/src/js/eshop-watchdog/index_bs5";
import "@nxcms/nxcms-js/src/js/gdpr/index_bs5";

import "@nxcms/nxcms-js/src/js/recipes/index";

window.jQuery = window.$ = $;

import "slick-carousel/slick/slick";

const navbarScroll = document.getElementById("navbarScroll");
const navbarCategories = document.getElementById("navbarCategories");

$(window).scroll(function () {
  const scrolled =
    document.body.scrollTop > 80 || document.documentElement.scrollTop > 80;
  navbarScroll.classList.toggle("navbar-scrolled", scrolled);
  navbarCategories.classList.toggle("navbar-scrolled", scrolled);
});

let bannerSlider = $(".section.banners .sliderWrapper .slider");
if (bannerSlider.length) {
  let bannerContainer = bannerSlider.closest(".section.banners");
  handleBannerWidth();

  bannerSlider.slick({
    infinite: true,
    cssEase: "cubic-bezier(0.58, 0, 0.22, 1)",
    prevArrow: bannerContainer.find(".nav-controls .nav-prev"),
    nextArrow: bannerContainer.find(".nav-controls .nav-next"),
    variableWidth: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  bannerSlider.on("beforeChange", function () {
    bannerContainer.find(".nav-controls .nav-prev").addClass("enabled");
  });

  function handleBannerWidth() {
    bannerSlider.find(".banner").each(function () {
      let bannerSliderWidth = bannerContainer.width();
      if (window.innerWidth < 992) {
        $(this).css("width", bannerSliderWidth);
        return;
      }

      if ($(this).hasClass("banner--narrow")) {
        $(this).css("width", bannerSliderWidth * 0.33333 - 8);
      } else {
        $(this).css("width", bannerSliderWidth * 0.66666 - 8);
      }
    });
  }

  window.addEventListener(
    "resize",
    function () {
      handleBannerWidth();
    },
    true
  );
}

let productCategories = $(".section.productCategories .slider");
if (productCategories.length) {
  productCategories.slick({
    infinite: true,
    prevArrow: productCategories.parent().find(".nav-controls .nav-prev"),
    nextArrow: productCategories.parent().find(".nav-controls .nav-next"),
    cssEase: "cubic-bezier(0.58, 0, 0.22, 1)",
    mobileFirst: true,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });

  productCategories.on("beforeChange", function () {
    productCategories
      .parent()
      .find(".nav-controls .nav-prev")
      .addClass("enabled");
  });
}
